import { useState } from "react";
import "./App.css";

function Polinom() {
    const [mod,setmod]=useState(1);
    const [base, setBase] = useState(1);
    const [inputValues, setInputValues] = useState([]);
    const [result, setResult] = useState([]);
    function Up(base, exponent, modulus) {
        base = base % modulus;
        let result = 1;
      
        while (exponent > 0) {
          if (exponent % 2 === 1) {
            result = (result * base) % modulus;
          }
          base = (base * base) % modulus;
          exponent = Math.floor(exponent / 2);
        }
      
        return result;
      }
    function Count() {
        const currentMod = mod;
        let a=[];
        let b=[];
        for (let i=0;i<inputValues.length;i++){
            a[i]=inputValues[i];
        }
        console.log(a);
        for (let j=1;j<mod;j++){
            let t=0;
            for (let n=0;n<a.length;n++){
                let x=Up(j, base-n-1, currentMod);
                console.log(a[n]*x);
                t=(t+(a[n]*x))%currentMod;
            }
            console.log(t);
            if (t===0){
                b.push(j);
            }
        }
        setResult(b);


    }
    return (
        <div className="PSV" style={styles.container}>
            <p >Новое<br/>
            Найти все корни<br/></p>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setmod(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base1" style={styles.label}>
                   Количество элементов
                </label>
                <select
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => { setBase(Math.abs(parseInt(e.target.value))) }}
                >
                    {[...Array(20).keys()].map((num) => (
                        <option key={num} value={num}>
                            {num}
                        </option>
                    ))}
                </select>
            </div>
            {(
                <>
                    {[...Array(base)].map((_, number) => (
                        <div className="input-field" style={styles.inputField} key={number}>
                            <label htmlFor={`base${number}`} style={styles.label}>
                                Элемент {number + 1}
                            </label>
                            <input
                                type="number"
                                id={`base${number}`}
                                style={styles.input}
                                value={inputValues[number]===null?1:inputValues[number]}
                                onChange={(e) => {
                                    const updatedInputValues = [...inputValues];
                                    updatedInputValues[number] = parseInt(e.target.value);
                                    setInputValues(updatedInputValues);
                                }}
                            />
                        </div>
                    ))}
                </>
            )}
            <button
                onClick={Count}
                className="text-field__btn"
                style={{ marginTop: "1rem" }}
            >
                Принять
            </button>
            <p style={styles.result}>
                Результат:{" "}
                {result === "Модуль не найден"
                    ? result
                    : result.join(" ")}
            </p>
        </div>
    );
}



const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};


export default Polinom;
