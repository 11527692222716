import React, { useState } from "react";
import "./App.css";

function Sds() {
    const [base, setBase] = useState(1);
    const [equal, setEqual] = useState(1);
    const [mod, setmod] = useState(1);
    const [mnoj, setMnoj]=useState(1);
    const [result, setResult] = useState([]);
    function Up(base, exponent, modulus) {
        base = base % modulus;
        let result = 1;
      
        while (exponent > 0) {
          if (exponent % 2 === 1) {
            result = (result * base) % modulus;
          }
          base = (base * base) % modulus;
          exponent = Math.floor(exponent / 2);
        }
      
        return result;
      }
    function calculateShower() {
        const currentBase = base;
        const currentEqual=equal%mod;
        const currentMod = mod;
        const currentMnoj=mnoj%currentMod;
        let a=[];
        for (var j = 1; j < currentMod; j++) {
            let result = Up(j, currentBase, currentMod);
            //console.log(result);
            result=(result*currentMnoj)%currentMod;
            if (result === currentEqual) {
                a.push(j);
                //console.log(j);
            }
        }
        if (a.length<1){
            setResult("Не найдено");
        }
        else{
            setResult(a);
        }
    }




    return (
        <div className="Shower" style={styles.container}>
            <p >5 задание КТ2</p>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Множитель X</label>
                <input
                    type="number"
                    id="mnoj"
                    style={styles.input}
                    value={mnoj}
                    onChange={(e) => setMnoj(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Степень</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Чему равно</label>
                <input
                    type="number"
                    id="equal"
                    style={styles.input}
                    value={equal}
                    onChange={(e) => setEqual(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setmod(parseInt(e.target.value))}
                />
            </div>
            <button onClick={calculateShower} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Вычислить наименьшее число
            </button>
            <p style={styles.result}>Результат: {result==="Не найдено"? result : result.join(' ')}</p>
            <p >(-1)=Нерешаемо</p>
            <p >На парах X всегда был без множителя<br/>
            Множитель добавлен на всякий случай<br/>
            как усложнение, но можно просто оставить<br/>
            его равным 1</p>
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default Sds;
