import React, { useState } from "react";
import "./App.css";

function Pollard() {
    const [base, setBase] = useState(1);
    const [result, setResult] = useState(1);
    const [result2, setResult2] = useState(1);
    function gcd(a, b) {
        while (b !== 0) {
          let temp = b;
          b = a % b;
          a = temp;
        }
        return a;
      }
    function PollardCalc() {
        let n=base;
        if (n === 1) return 1; // Особый случай: если число равно 1, возвращаем 1
  
        // Функция для вычисления f(x) = x^2 + 1 (можно использовать другую функцию)
        const f = x => (x * x + 1) % n;
      
        let x = 2, y = 2, d = 1;
        while (d === 1) {
          x = f(x);
          y = f(f(y));
          d = gcd(Math.abs(x - y), n);
        }
      
        if (d === n) {
          // Если алгоритм не смог найти делитель, вернуть -1
          setResult(-1);
          return -1;
        } else {
          // Иначе вернуть найденный делитель
          setResult(d);
          setResult2(base/d);
          return d;
        }
    }




    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Число</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <button onClick={PollardCalc} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Факторизовать
            </button>
            <p style={styles.result}>Результат: {result}   {result2}</p>
            <p >(-1)-Отстутствие чисел такого порядка</p>
            <p >4 задание КТ2</p>
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default Pollard;
