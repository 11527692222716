import React, { useState } from "react";
import "./App.css";
function Glad() {
    
    const [base, setBase] = useState(1);
    const [mod, setmod] = useState(1);
    const [Adleman, setAdleman] = useState(1);
    const [Adleman2, setAdleman2] = useState(1);
    const [result, setResult] = useState([]);
    const [result2, setResult2] = useState([]);
    const [result3, setResult3] = useState([]);
    const [result4, setResult4] = useState([]);

    function factorize() {
        
        const p=mod;
        let maxPrimeFactor=base;
        const rand= Adleman;
        const c=Adleman2;

        function getPrimeFactors(n) {
            const factors = [];
            let divisor = 2;
        
            while (n >= 2) {
              if (n % divisor === 0) {
                factors.push(divisor);
                n /= divisor;
              } else {
                divisor++;
              }
            }
        
            return factors;
          }
        
          const specialNumbers = [];
        
          // Раскладываем числа от 1 до p на простые множители
          for (let i = 2; i <= p; i++) {
            const primeFactors = getPrimeFactors(i);
        
            // Проверяем простые множители числа на условие
            if (Math.max(...primeFactors) <= maxPrimeFactor) {
              specialNumbers.push(i);
            }
            setResult(specialNumbers);
          }
          let resulttt=1;
          let arr=[];
          for (let i = 1; i < p; i++) {
            resulttt = (resulttt * rand) % p;
            for (let j=0;j<specialNumbers.length;j++){
                if (resulttt===specialNumbers[j]){
                    arr.push(i);
                    break;
                }
            } 
          }
          let b=1;
          let brr=[];
          for (let i = 1; i < p; i++) {
            b = (b * rand) % p;
            let temp=(b*c)%p;
            for (let j=0;j<specialNumbers.length;j++){
                if (temp===specialNumbers[j]){
                    brr.push(i);
                    break;
                }
            } 
          }
          let dix=[];
          for (let i = 1; i < p; i++) {
            b = (i * i) % p;
            for (let j=0;j<specialNumbers.length;j++){
                if (b===specialNumbers[j]){
                    dix.push(i);
                    break;
                }
            } 
          }
          console.log(dix);
          setResult2(arr);
          setResult3(brr);
          setResult4(dix);
        }
    
    
    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <h1>Здесь вы можете генерировать числа для примеров Адлемана и Диксона</h1>
                <label htmlFor="base" style={styles.label}>Граница гладкости</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setmod(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="Adleman" style={styles.label}>Число a для Адлемана</label>
                <input
                    type="number"
                    id="Adleman"
                    style={styles.input}
                    value={Adleman}
                    onChange={(e) => setAdleman(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="Adleman2" style={styles.label}>Число b для Адлемана</label>
                <input
                    type="number"
                    id="Adleman2"
                    style={styles.input}
                    value={Adleman2}
                    onChange={(e) => setAdleman2(parseInt(e.target.value))}
                />
            </div>
            <button onClick={factorize}           className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Найти
            </button>
            <p style={styles.result}>Гладкие числа: {result.join(' ')}</p>
            <p style={styles.result}>Степени числа a являющиеся гладкими: {result2.join(' ')}</p>
            <p style={styles.result}>При каких n (a^n)*b гладкое число:  {result3.join(' ')}</p>
            <p style={styles.result}>Числа, квадраты которых r-гладкие(Для Диксона):<br/>{result4.join(' ')}</p>

            
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s", // Добавляем анимацию изменения цвета
    },
    buttonHover: {
        backgroundColor: "#0056b3", // Новый цвет при наведении
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};


export default Glad;
