import React, { useState } from "react";
import "./App.css";

function ChooseNum() {
    const [base, setBase] = useState(1);
    const [mod, setmod] = useState(1);
    const [result, setResult] = useState(1);
    function Up(base, exponent, modulus) {
        base = base % modulus;
        let result = 1;
      
        while (exponent > 0) {
          if (exponent % 2 === 1) {
            result = (result * base) % modulus;
          }
          base = (base * base) % modulus;
          exponent = Math.floor(exponent / 2);
        }
      
        return result;
      }
    function calculateShower() {
        const currentBase = base;
        const currentMod = mod;
        for (var j = 1; j < currentMod; j++) {
            let a=0;
            let result = Up(j, currentBase, currentMod);
            //console.log(result);
            if (result === 1) {
                console.log(j);
                for (let t=1;t<currentBase;t++){
                    if ((j**t)%currentMod===1){
                        a+=1;
                    }
                }
                if (a===0){
                    setResult(j)
                    break;
                }
            }
            if (j===currentMod-1){
                setResult(-1);
            }
        }
    }




    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Порядок</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setmod(parseInt(e.target.value))}
                />
            </div>
            <button onClick={calculateShower} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Вычислить наименьшее число
            </button>
            <p style={styles.result}>Результат: {result}</p>
            <p >(-1)-Отстутствие чисел такого порядка</p>
            <p >4 задание КТ2</p>
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default ChooseNum;
