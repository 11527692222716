import React, { useState } from "react";
import "./App.css";

function Tower() {
    const [base, setBase] = useState(1);
    const [exponent, setExponent] = useState(1);
    const [dexponent, setDExponent] = useState(1);
    const [modulus, setModulus] = useState(1);
    const [result, setResult] = useState(0);
    function Up(base, exponent, modulus) {
        base = base % modulus;
        let result = 1;
      
        while (exponent > 0) {
          if (exponent % 2 === 1) {
            result = (result * base) % modulus;
          }
          base = (base * base) % modulus;
          exponent = Math.floor(exponent / 2);
        }
      
        return result;
      }
    function eulerPhi(n) {
        if (n <= 1) {
            return 0;
        }

        let result = n; // Изначально считаем, что все числа взаимно просты

        for (let i = 2; i * i <= n; i++) {
            if (n % i === 0) {
                while (n % i === 0) {
                    n /= i;
                }
                result -= result / i;
            }
        }

        if (n > 1) {
            result -= result / n;
        }

        return result;
    }

    function calculatePower() {
        // Реализация вычисления степенной башни по модулю
        let f = eulerPhi(modulus);
        let f2=eulerPhi(f);
        console.log(f, f2);
        let exp2=dexponent%f2;
        if (exp2===0){
            exp2=f2;
        }
        let exp1=Up(exponent, exp2,f);
        if (exp1===0){
            exp1=f;
        }
        let one=base%modulus;
        console.log(one, exp1, exp2);
        const result = Up(one, exp1, modulus);
        console.log(result);
        setResult(result);
    }

    return (
        <div className="Tower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Основание:</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Показатель степени:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={exponent}
                    onChange={(e) => setExponent(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Показатель показателя степени:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={dexponent}
                    onChange={(e) => setDExponent(parseInt(e.target.value))}
                />
            </div>

            <div className="input-field" style={styles.inputField}>
                <label htmlFor="modulus" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="modulus"
                    style={styles.input}
                    value={modulus}
                    onChange={(e) => setModulus(parseInt(e.target.value))}
                />
            </div>

            <button onClick={calculatePower} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Вычислить степенную башню по модулю
            </button>

            <p style={styles.result}>Результат: {result}</p>
            <p>Решение 3-го номера КТ2</p>
        </div>
    );
}

const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default Tower;
