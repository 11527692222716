import React, { useState } from "react";
import PR from "./PR";
import Tower from "./Tower";
import Shower from "./Shower";
import ChooseNum from "./ChooseNum";
import ShowEqual from "./ShowEqual";
import Sds from "./Sds";
import PSVmax from "./PSVmax";
import Polinom from "./Polinom"
import Pollard from "./Pollard";
import PollardFloid from "./Pollard-Floid";
import PollardP1 from "./Pollard(p-1)";
import Dixson from "./Dixson";
import Ellips from "./Ellips";
import Glad from "./Glad";
function App() {
  const [activeTab, setActiveTab] = useState("tab1");

  const tabComponents = {
    tab1: <PR />,
    tab2: <Tower />,
    tab3: <Shower />,
    tab4: <ChooseNum />,
    tab5: <ShowEqual />,
    tab6: <Sds />,
    tab7: <PSVmax />,
    tab8: <Polinom />,
    tab9: <Pollard />,
    tab10: <PollardFloid />,
    tab11: <PollardP1 />,
    tab12: <Dixson />,
    tab13: <Ellips />,
    tab14: <Glad />
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="App">
      <div className="tabs">
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab1")}
        >
          Первообразные корни
        </button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab2")}
        >
          Степенная башня
        </button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab3")}
        >
          Порядок по модулю
        </button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab4")}
        >Число заданного порядка</button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab5")}
        >Адлеман</button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab6")}
        >Сравнение x^n=b</button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab7")}
        >ПСВ</button>
        <button
          className="primary-button"
          onClick={() => handleTabChange("tab8")}
        >Полином</button>
                <button
          className="primary-button"
          onClick={() => handleTabChange("tab9")}
        >Поллард</button>
                        <button
          className="primary-button"
          onClick={() => handleTabChange("tab10")}
        >Поллард-Флойд</button>
                        <button
          className="primary-button"
          onClick={() => handleTabChange("tab11")}
        >Поллард p-1</button>
                                <button
          className="primary-button"
          onClick={() => handleTabChange("tab12")}
        >Диксон</button>
                                        <button
          className="primary-button"
          onClick={() => handleTabChange("tab13")}
        >Эллипс</button>
                                                <button
          className="primary-button"
          onClick={() => handleTabChange("tab14")}
        >Гладкие числа</button>
      </div>
      <div className="tab-content">
        {tabComponents[activeTab]}
      </div>
    </div>
  );
}


export default App;
