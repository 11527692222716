import React, { useState } from "react";
import "./App.css";

function Dixson() {
    const [base, setBase] = useState(1);
    const [result, setResult] = useState(1);
    const [result2, setResult2] = useState(1);
// Функция для вычисления наибольшего общего делителя
function gcd(a, b) {
    if (b === 0) {
        return a;
    } else {
        return gcd(b, a % b);
    }
}

// Функция для поиска факторов числа с использованием алгоритма Диксона
function dixonFactorization() {
    const n=base
    const maxIterations = 1000; // Максимальное количество итераций
    const smoothnessBound = 100; // Параметр гладкости
    const factors = []; // Массив для хранения найденных делителей

    // Функция для генерации квадратичных вычетов
    function generateQuadraticResidue(x, c) {
        return (x * x - c);
    }

    let x = 2;
    let y = 2;
    let iteration = 0;

    while (iteration < maxIterations && factors.length === 0) {
        x = generateQuadraticResidue(x, n) % n;
        y = generateQuadraticResidue(generateQuadraticResidue(y, n), n) % n;

        const factor = gcd(Math.abs(x - y), n);
        if (factor !== 1 && factor !== n) {
            factors.push(factor);
        }

        iteration++;
    }

    if (factors.length === 0) {
        setResult (-1);
        setResult2 (-1);
        return `Не удалось найти делители для числа ${n} с использованием алгоритма Диксона.`;
    } else {
        setResult (factors[0]);
        setResult2(base/factors[0])
        return `Делители числа ${n}: ${factors.join(', ')}`;
    }
}


    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Число</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <button onClick={dixonFactorization} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Факторизовать
            </button>
            <p style={styles.result}>Результат: {result}   {result2}</p>
            <p >(-1)-Отстутствие чисел такого порядка</p>
            <p >4 задание КТ3</p>
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default Dixson;
