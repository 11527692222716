import React, { useState } from "react";
import "./App.css";
function Shower() {
    
    const [base, setBase] = useState(1);
    const [mod, setmod] = useState(1);
    const [result, setResult] = useState(1);
    const NOD = (a, b) => {
        while (b !== 0) {
          const temp = b;
          b = a % b;
          a = temp;
        }
        return a === 1;
      };
    function calculateShower() {
        const currentBase = base%mod ;
        const currentMod = mod;
        if (mod===0){
            setResult(-2);
            return 0;
        }
        if (!NOD(currentMod, currentBase)){
            setResult(-1);
            return 0;
        }
        let result = 1;
    
        for (var j = 1; j < currentMod; j++) {
            result = (result * currentBase) % currentMod;
            if (result === 1) {
                setResult(j)
                break;
            }
        }
    }
    
    
    
    
    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Число:</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <div className="input-field" style={styles.inputField}>
                <label htmlFor="exponent" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="exponent"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setmod(parseInt(e.target.value))}
                />
            </div>
            <button onClick={calculateShower}           className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Вычислить порядок числа
            </button>
            <p style={styles.result}>Результат: {result}</p>
            <p >(-1)- Число и модуль не взаимно просты</p>
            <p >4 задание КТ2</p>
            
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s", // Добавляем анимацию изменения цвета
    },
    buttonHover: {
        backgroundColor: "#0056b3", // Новый цвет при наведении
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};


export default Shower;
