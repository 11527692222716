import React, { useState } from "react";
import "./App.css";

function PollardP1() {
    const [base, setBase] = useState(1);
    const [result, setResult] = useState(1);
    const [result2, setResult2] = useState(1);
    function gcd(a, b) {
        while (b !== 0) {
            let temp = b;
            b = a % b;
            a = temp;
        }
        return a;
    }
    
    function powerMod(base, exponent, modulus) {
        if (modulus === 1) return 0;
        let result = 1;
        base = base % modulus;
        while (exponent > 0) {
            if (exponent % 2 === 1) {
                result = (result * base) % modulus;
            }
            exponent = Math.floor(exponent / 2);
            base = (base * base) % modulus;
        }
        return result;
    }
    
    function PollardPMinusOne() {
        let n = base;
        if (n === 1) return 1; // Особый случай: если число равно 1, возвращаем 1
    
        let a = 2; // Выбираем начальное значение a
        const maxIterations = 100; // Максимальное количество итераций
    
        for (let j = 2; j <= maxIterations; j++) {
            a = powerMod(a, j, n);
            const d = gcd(a - 1, n);
    
            if (d > 1 && d < n) {
                // Найден делитель
                setResult(d);
                setResult2(n / d);
                return d;
            }
    
            if (d === n) {
                // Если алгоритм не смог найти делитель, вернуть -1
                setResult(-1);
                return -1;
            }
        }
    
        // Если достигнуто максимальное количество итераций
        setResult(-1);
        return -1;
    }
    
    




    return (
        <div className="Shower" style={styles.container}>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base" style={styles.label}>Число</label>
                <input
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => setBase(parseInt(e.target.value))}
                />
            </div>

            <button onClick={PollardPMinusOne} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Факторизовать
            </button>
            <p style={styles.result}>Результат: {result}   {result2}</p>
            <p >(-1)-Отстутствие чисел такого порядка</p>
            <p >4 задание КТ2</p>
        </div>
    );
}
const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default PollardP1;
