import React, { useState } from "react";
import "./App.css";

function Ellips() {
    const [a, setA] = useState(1);
    const [x0, setX0] = useState(1);
    const [mod, setMod] = useState(1);
    const [y0, setY0] = useState(1);
    const [result, setResult] = useState([]);
    const [topresult, setTopResult] = useState([]);

    function addPoints(x1, y1, x2, y2) {
        const p=mod;
        // Проверка на случай, если одна из точек - бесконечность
        if (x1 === null && y1 === null) {
            return [x2, y2];
        } else if (x2 === null && y2 === null) {
            return [x1, y1];
        }
    
        // Проверка на случай, если точки равны (случай удвоения)
        if (x1 === x2 && y1 === y2) {
            return doublePoint();
        }
        console.log(x1, y1, x2, y2);
        // Вычисление углового коэффициента прямой, соединяющей точки (x1, y1) и (x2, y2)
        const slope = (((y2 - y1) * modInverse(x2 - x1, p)) % p + p) % p;
    console.log(slope);
        // Нахождение x3
        const x3 = (slope * slope - x1 - x2) % p;
    
        // Нахождение y3
        const y3 = (slope * (x1 - x3) - y1) % p;
    setTopResult([(x3 + p) % p, (y3 + p) % p]);
        return [(x3 + p) % p, (y3 + p) % p];
    }
    function doublePoint() {
        let x=x0;
        let y=y0;
        let p=mod;
        // Проверка, является ли точка на бесконечности (то есть O)
        if (x === null || y === null) {
            return [null, null];
        }
    
        // Нахождение производной кривой в точке (x, y)
        const slope = ((3 * x * x + a) * modInverse(2 * y, p)) % p;
    
        // Нахождение x3
        const x3 = (slope * slope - 2 * x) % p;
    
        // Нахождение y3
        const y3 = (slope * (x - x3) - y) % p;
        addPoints(x,y,(x3 + p) % p,(y3 + p) % p);
        setResult([(x3 + p) % p, (y3 + p) % p])
        return [(x3 + p) % p, (y3 + p) % p];
    }
    
    // Функция для нахождения обратного элемента по модулю p
    function modInverse(e, p) {
        let b = ((e % p) + p) % p;
        for (let i = 1; i < p; i++) {
            if ((b * i) % p === 1) {
                return i;
            }
        }
        return NaN;
    }

    return (
        <div className="Shower" style={styles.container}>
            <p >5 задание КТ3</p>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="a" style={styles.label}>Значение а:</label>
                <input
                    type="number"
                    id="a"
                    style={styles.input}
                    value={a}
                    onChange={(e) => setA(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="a" style={styles.label}>x0</label>
                <input
                    type="number"
                    id="x0"
                    style={styles.input}
                    value={x0}
                    onChange={(e) => setX0(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="y0" style={styles.label}>y0</label>
                <input
                    type="number"
                    id="y0"
                    style={styles.input}
                    value={y0}
                    onChange={(e) => setY0(parseInt(e.target.value))}
                />
            </div>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="mod" style={styles.label}>Модуль:</label>
                <input
                    type="number"
                    id="mod"
                    style={styles.input}
                    value={mod}
                    onChange={(e) => setMod(parseInt(e.target.value))}
                />
            </div>
            <button onClick={doublePoint} className="text-field__btn"
          style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                Вычислить значения
            </button>
            <p style={styles.result}>Удвоение: {result === "Не найдено" ? result : result.join(' ')}</p>
            <p style={styles.result}>Результат: {result === "Не найдено" ? topresult : topresult.join(' ')}</p>
            <p >(-1)=Нерешаемо</p>
        </div>
    );
}

const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};

export default Ellips;
