import { useState } from "react";
import "./App.css";

function PSVmax() {
    function eulerPhi(n) {
        if (n <= 1) {
            return 0;
        }

        let result = n; // Изначально считаем, что все числа взаимно просты

        for (let i = 2; i * i <= n; i++) {
            if (n % i === 0) {
                while (n % i === 0) {
                    n /= i;
                }
                result -= result / i;
            }
        }

        if (n > 1) {
            result -= result / n;
        }

        return result;
    }
    const [base, setBase] = useState(1);
    const [inputValues, setInputValues] = useState([]);
    const [result, setResult] = useState([]);
    const NOD = (a, b) => {
        while (b !== 0) {
            const temp = b;
            b = a % b;
            a = temp;
        }
        return a === 1;
    };
    function Count() {
        let a = [];
        let b = [];
            if (inputValues===null){
                setResult("Модуль не найден");
                return 0;
            }
        console.log(inputValues);
        for (let i = 2; i < 1000; i++) {
            for (let j = 0; j < base; j++) {
                a[j] = inputValues[j] % i;
                if (a[j] < 0) {
                    a[j] += i;
                }
            }
            for (let z=0;z<base;z++)
            {
                if (a[z]===null){
                    setResult("Модуль не найден");
                    return 0;
                }
            }
            let t = false;
            for (let j = 0; j < base; j++) {
                if (!NOD(a[j], i)) {
                    t = true;
                    break;
                }
            }
            if (t === true) { continue; }
            let w = false;
            for (let p = 0; p < a.length; p++) {
                for (let s = p + 1; s < a.length; s++) {
                    if (a[p] === a[s]) {
                        w = true;
                        break;
                    }
                }
            }
            if (w === true) { continue; }
            if (eulerPhi(i) === base) {
                b.push(i);
            }
        }
        if (b.length > 0) {
            setResult(b);
        } else {
            setResult("Модуль не найден");
        }


    }
    return (
        <div className="PSV" style={styles.container}>
            <p >3 задание КТ2<br/>
            Найти модуль, по которому<br/> данное множество-приведенная система вычетов</p>
            <div className="input-field" style={styles.inputField}>
                <label htmlFor="base1" style={styles.label}>
                    Количество элементов
                </label>
                <select
                    type="number"
                    id="base"
                    style={styles.input}
                    value={base}
                    onChange={(e) => { setBase(Math.abs(parseInt(e.target.value))) }}
                >
                    {[...Array(20).keys()].map((num) => (
                        <option key={num} value={num}>
                            {num}
                        </option>
                    ))}
                </select>
            </div>
            {(
                <>
                    {[...Array(base)].map((_, number) => (
                        <div className="input-field" style={styles.inputField} key={number}>
                            <label htmlFor={`base${number}`} style={styles.label}>
                                Элемент {number + 1}
                            </label>
                            <input
                                type="number"
                                id={`base${number}`}
                                style={styles.input}
                                value={inputValues[number]===null?1:inputValues[number]}
                                onChange={(e) => {
                                    const updatedInputValues = [...inputValues];
                                    updatedInputValues[number] = parseInt(e.target.value);
                                    setInputValues(updatedInputValues);
                                }}
                            />
                        </div>
                    ))}
                </>
            )}
            <button
                onClick={Count}
                className="text-field__btn"
                style={{ marginTop: "1rem" }}
            >
                Принять
            </button>
            <p style={styles.result}>
                Результат:{" "}
                {result === "Модуль не найден"
                    ? result
                    : result.join(" ")}
            </p>
        </div>
    );
}



const styles = {
    container: {
        textAlign: "center",
        marginTop: "20px",
    },
    inputField: {
        marginBottom: "10px",
    },
    label: {
        display: "block",
        fontSize: "18px",
    },
    input: {
        width: "100px",
        padding: "5px",
        fontSize: "16px",
    },
    button: {
        backgroundColor: "#007bff",
        color: "white",
        padding: "10px 20px",
        fontSize: "18px",
        border: "none",
        cursor: "pointer",
    },
    result: {
        fontSize: "20px",
        fontWeight: "bold",
    },
};


export default PSVmax;
