import { useState } from "react";
import "./App.css";

function PR() {
  const [inputText, setInputText] = useState(""); // Состояние для введенного текста
  const [plenty, setPlenty] = useState([]);
  const [Order, setOrder] = useState(0);
  const [FiRoot, setFiRoot] = useState([]);
  function AddDouble(a, b, mod){
    a = a % mod;
    b = b % mod;
    let c = 0;
  
    while (a > 0) {
      if (a % 2 === 1) {
        c = (c + b) % mod;
      }
      a = Math.floor(a / 2);
      b = (b * 2) % mod;
    }
  
    return c;
  }
  function Up(base, exponent, modulus) {
    base = base % modulus;
    let result = 1;
  
    while (exponent > 0) {
      if (exponent % 2 === 1) {
        result = (result * base) % modulus;
      }
      base = (base * base) % modulus;
      exponent = Math.floor(exponent / 2);
    }
  
    return result;
  }
  
  function FRoot() {
    let mnoj = {};
  let c=[];
    for (let i = 0; i < plenty.length; i++) {
  let b=0;
      let a = [];
      for (let j = 1; j <= plenty.length + 1; j++) {
        let result = Up(plenty[i], j, Order);
        a.push(result);
  if (result===1){
    b+=1;
  }
      }
      if (b===1){
        c.push(plenty[i]);
      }
      mnoj[plenty[i]]=a;
    }
    console.log(mnoj);
    console.log(c);
    setFiRoot(c);
  }
  

  const NOD = (a, b) => {
    while (b !== 0) {
      const temp = b;
      b = a % b;
      a = temp;
    }
    return a === 1;
  };

  const handleButtonClick = () => {
    const num = parseInt(inputText, 10);
    setOrder(num);

    if (!isNaN(num)) {
      const coprimeNumbers = [];
      for (let i = 1; i < num; i++) {
        if (NOD(num, i)) {
          coprimeNumbers.push(i);
        }
      }
      setPlenty(coprimeNumbers);
    }
  };
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  return (
    <div className="PR">
<p>Эта вкладка нужна для подбора <br />правой части в сравнениях,<br />где правая часть - первообразный корень,
<br />то есть придумать пример <br />для 5-го задания можно здесь.<br /><br />Найти-нахождение всех чисел<br /> взаимно простых с модулем<br /></p>


      <div className="text-field">
        <label className="text-field__label" htmlFor="search">
          Введите модуль:
        </label>
        <div className="text-field__group">
          <input
            className="text-field__input"
            type="search"
            id="search"
            name="search"
            placeholder="Введите число"
            value={inputText}
            onChange={handleInputChange} // Обработчик изменения input
          />
          <button
            className="text-field__btn"
            type="button"
            onClick={handleButtonClick}
          >
            Найти
          </button>
        </div>
        <button
          className="text-field__btn"
          style={{ marginTop: "2rem", marginLeft: "2rem" }}
          type="button"
          onClick={FRoot}
        >
          Найти первообразные корни
        </button>
      </div>
      {plenty.length > 0 && (
        <div>
          <p>Фи-функция:{plenty.length}</p>
          <p>Первообразные корни: {FiRoot.join(' ')}</p>
          <p>Числа взаимно простые с {Order}:</p>
          <ul>
            {plenty.map((number) => (
              <li key={number}>{number}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default PR;
